import { changeOpenedId, changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { IBase } from "@monorepo/utils/src/store/types/baseState";
import { ISection } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export const mutations: Record<string, unknown> = {
  refreshData<T extends IBase>(state: T) {
    state.data = [];
  },
  setSearchTemplates<T extends IBase>(state: T, payload: ISearchTemplate[]) {
    state.searchTemplates = payload;
  },
  setFilters<T extends IBase>(state: T, payload: ITableFiltersObject) {
    state.filters = payload;
  },
  setViewMode<T extends IBase>(state: T, payload?: { id?: number; typeDisplay: ViewMode }) {
    if (payload) {
      state.viewMode = { ...payload };
    }
  },
  addInitMessagesLength<T extends IBase>(state: T, payload: number) {
    state.filters.initMessagesLength = payload;
  },
  changeOpenedId<T extends IBase>(state: T, payload: changeOpenedIdPayloadValues) {
    changeOpenedId(state, payload);
  },
  addFilter<T extends IBase>(state: T, payload: Record<string, SORT_TYPE>) {
    state.filters.fieldFilters = {
      ...state.filters.fieldFilters,
      ...payload,
    };
  },
  refreshInfiniteId<T extends IBase>(state: T) {
    state.infiniteId = new Date().toString();
  },
  setIsSelectAll<T extends IBase>(state: T, payload: boolean | null) {
    state.filters.isSelectAll = payload;
  },
  addOpenedId<T extends IBase>(state: T, payload: null | string | number) {
    state.filters.openedId = payload;
  },
  addSort<T extends IBase>(state: T, payload: Record<string, SORT_TYPE>) {
    state.filters.sort = { ...state.filters.sort, ...payload };
  },
  setCells<T extends IBase>(state: T, payload: string[]) {
    state.cells = payload;
  },
  setIsOpenFilters<T extends IBase>(state: T, payload: boolean) {
    state.isOpenFilters = payload;
  },
  setAutorefresh<T extends IBase>(state: T, payload: boolean) {
    state.autorefresh = payload;
  },
  setIsLoadingToggleFilters<T extends IBase>(state: T, payload: boolean) {
    state.isLoadingToggleFilters = payload;
  },
  setIsLoadingChangeAutorefresh<T extends IBase>(state: T, payload: boolean) {
    state.isLoadingChangeAutorefresh = payload;
  },
  setSelectedIds<T extends IBase>(state: T, payload: Record<string, boolean>) {
    state.filters.selectedIds = payload;
  },
  setSectionCells<T extends IBase>(state: T, payload: ISection) {
    state.sectionCells = payload;
  },
};
