




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
