import { IBase } from "@monorepo/utils/src/store/types/baseState";
import { CardMode } from "@monorepo/utils/src/types/cardMode";

export enum changeOpenedIdPayloadValues {
  UP = "up",
  DOWN = "down",
}

export const changeOpenedId = <T extends IBase>(state: T, payload: changeOpenedIdPayloadValues): void => {
  const selectedIndex = state.data.findIndex((element) => element.id == state.filters.openedId);

  if (payload === changeOpenedIdPayloadValues.UP) {
    state.filters.openedId = selectedIndex === 0 ? state.data[state.data.length - 1].id : state.data[selectedIndex - 1].id;
  }

  if (payload === changeOpenedIdPayloadValues.DOWN) {
    state.filters.openedId = selectedIndex === state.data.length - 1 ? state.data[0].id : state.data[selectedIndex + 1].id;
  }
};

type changeOpenedId = (payload: changeOpenedIdPayloadValues) => unknown;

export function checkKeyPressToChangeOpenedId(this: { changeOpenedId: changeOpenedId; cardModeResult: CardMode }, e: Event): void {
  if (this.cardModeResult === CardMode.FULL) {
    return;
  }

  if ((e as KeyboardEvent).key === "ArrowDown") {
    this.changeOpenedId(changeOpenedIdPayloadValues.DOWN);
  }

  if ((e as KeyboardEvent).key === "ArrowUp") {
    this.changeOpenedId(changeOpenedIdPayloadValues.UP);
  }
}
