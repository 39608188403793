import { IBase } from "@monorepo/utils/src/store/types/baseState";
import { GetterTree } from "vuex";

export type IRootState = Record<string, never>;

export const getters: GetterTree<IBase, IRootState> = {
  cells<T extends IBase>(state: T) {
    return state?.cells ?? [];
  },
  section<T extends IBase>(state: T) {
    return state?.section ?? "";
  },
  viewMode<T extends IBase>(state: T) {
    return state?.viewMode ?? {};
  },
  searchTemplates<T extends IBase>(state: T) {
    return state?.searchTemplates ?? [];
  },
  sectionCells<T extends IBase>(state: T) {
    return state?.sectionCells ?? [];
  },
  isOpenFilters<T extends IBase>(state: T) {
    return state?.isOpenFilters ?? [];
  },
  autorefresh<T extends IBase>(state: T) {
    return state?.autorefresh ?? false;
  },
  isLoadingToggleFilters<T extends IBase>(state: T) {
    return state?.isLoadingToggleFilters ?? false;
  },
  isLoadingChangeAutorefresh<T extends IBase>(state: T) {
    return state?.isLoadingChangeAutorefresh ?? false;
  },
  sort<T extends IBase>(state: T) {
    return state.filters.sort;
  },
  isSelectAll<T extends IBase>(state: T) {
    return state.filters.isSelectAll;
  },
  selectedIds<T extends IBase>(state: T) {
    return state.filters.selectedIds;
  },
  fieldFilters<T extends IBase>(state: T) {
    return state.filters.fieldFilters;
  },
  data<T extends IBase>(state: T) {
    return state.data;
  },
  initMessagesLength<T extends IBase>(state: T) {
    return state.filters.initMessagesLength;
  },
  totalLength<T extends IBase>(state: T) {
    return state.totalLength;
  },
  openedId<T extends IBase>(state: T) {
    return state.filters.openedId;
  },
  infiniteId<T extends IBase>(state: T) {
    return state.infiniteId;
  },
  statusesLibrary<T extends IBase>(state: T) {
    return state.libraries.statuses;
  },
};
