import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { Commit, Dispatch } from "vuex";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import axios from "axios";
import { ISection } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { convertItemFromObjToString } from "@monorepo/utils/src/api/convertHistoryItemToApi";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

// TODO do good types for store
export interface IVuexContext {
  commit: Commit;
  dispatch: Dispatch;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getters: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootGetters: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: Record<string, any>;
}

type Error = {
  response?: { data?: { message?: string } };
};

export const actions: Record<string, unknown> = {
  addFilter({ commit }: IVuexContext, payload: Record<string, SORT_TYPE>) {
    commit("addFilter", payload);
  },
  refreshData({ commit }: IVuexContext) {
    commit("refreshData");
  },
  async getSearchTemplates({ commit, getters, rootGetters, state }: IVuexContext) {
    const { data } = await getQuery<ISearchTemplate[]>(`${QUERY_PATH.GET_SEARCH_TEMPLATE}/${rootGetters["auth/user"].name}/${getters?.section}`);
    if (data !== null) {
      commit("setSearchTemplates", data);
      return { data: state?.searchTemplates || [] };
    }
    return { data: null };
  },
  async saveSearchTemplates({ getters, rootGetters, dispatch }: IVuexContext, payload: Partial<ISearchTemplate>) {
    try {
      await axios.post(QUERY_PATH.GET_SEARCH_TEMPLATE, {
        ...payload,
        section: getters?.section,
        username: rootGetters["auth/user"].name,
      });
      await dispatch("getSearchTemplates");
    } catch (e) {
      showNotification("Ошибка сохранения шаблона поиска: " + (e as unknown as Error).response?.data?.message ?? "");
    }
  },
  async deleteSearchTemplates({ dispatch }: IVuexContext, payload: ISearchTemplate) {
    try {
      await axios.delete(`${QUERY_PATH.GET_SEARCH_TEMPLATE}/${payload.id}`);
      await dispatch("getSearchTemplates");
    } catch (e) {
      console.error(e);
    }
  },
  async getViewMode({ commit, state, rootGetters }: IVuexContext, isOpenModalOnStart?: boolean) {
    try {
      const { data } = await axios.get(`${QUERY_PATH.GET_VIEW_MODES}/type-display/${rootGetters["auth/user"]?.details?.userId}/${state.section}`);
      const result = data?.data
        ? Object.entries(data.data).reduce((res: { id?: string; typeDisplay?: string }, current: [string, unknown]) => {
            res.id = current[0];
            res.typeDisplay = current[1] as string;
            return res;
          }, {})
        : data?.data;
      commit(
        "setViewMode",
        isOpenModalOnStart
          ? {
              ...(result || {}),
              typeDisplay: ViewMode.TABLE,
            }
          : result
      );
    } catch (e) {
      console.error(e);
    }
  },
  async changeViewMode({ commit, state, rootGetters }: IVuexContext, typeDisplay: ViewMode) {
    try {
      commit("setViewMode", { ...state.viewMode, typeDisplay });
      const payload = state.viewMode?.id
        ? `"${typeDisplay}"`
        : { userId: rootGetters["auth/user"]?.details?.userId, page: state.section, typeDisplay };
      const { data } = await axios[!state.viewMode?.id ? "post" : "put"](
        `${QUERY_PATH.GET_VIEW_MODES}${!state.viewMode?.id ? "" : "/" + state.viewMode.id}`,
        payload,
        state.viewMode?.id
          ? {
              headers: {
                "Content-Type": "application/json",
              },
            }
          : undefined
      );
      if (data?.data) {
        commit("setViewMode", {
          id: data.data.id,
          typeDisplay: data.data.typeDisplay,
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  clearFilters({ commit }: IVuexContext) {
    commit("clearFilters");
  },
  addSort({ commit }: IVuexContext, payload: Record<string, SORT_TYPE>) {
    commit("addSort", payload);
  },
  addOpenedId({ commit, dispatch, state }: IVuexContext, payload: null | string | number) {
    if (payload) {
      const cardItem = (state.data ?? []).find((item: { id: number }) => item.id === payload) || { id: payload };
      dispatch(
        "auth/saveUserHistory",
        {
          href: location.pathname,
          filters: convertItemFromObjToString(state.filters),
          section: state.section,
          item: convertItemFromObjToString({
            id: cardItem.id,
            number: cardItem.number || null,
            name: cardItem.name || null,
            createDate: cardItem.createDate || null,
            date: cardItem.date || null,
          }),
        },
        { root: true }
      );
    }
    commit("addOpenedId", payload);
  },
  changeOpenedId({ commit }: IVuexContext, payload: changeOpenedIdPayloadValues) {
    commit("changeOpenedId", payload);
  },
  async setIsOpenFilters({ commit, getters }: IVuexContext, payload: boolean) {
    if (!getters.sectionCells?.id) {
      commit("setIsOpenFilters", payload);
      return;
    }
    commit("setIsLoadingToggleFilters", true);
    try {
      await axios.put(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${getters.sectionCells?.id}`, {
        autorefresh: getters.sectionCells?.autorefresh,
        columnStates: getters.sectionCells?.columnStates,
        showFilter: payload,
      });
      commit("setIsOpenFilters", payload);
    } catch (e) {
      console.log(e);
    } finally {
      commit("setIsLoadingToggleFilters", false);
    }
  },
  async setAutorefresh({ commit, getters }: IVuexContext, payload: boolean) {
    if (!getters.sectionCells?.id) {
      return;
    }
    commit("setIsLoadingChangeAutorefresh", true);
    try {
      await axios.put(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${getters.sectionCells?.id}`, {
        autorefresh: payload,
        columnStates: getters.sectionCells?.columnStates,
        showFilter: getters.sectionCells?.showFilter,
      });
      commit("setAutorefresh", payload);
    } catch (e) {
      console.log(e);
    } finally {
      commit("setIsLoadingChangeAutorefresh", false);
    }
  },
  async setCells({ commit, getters }: IVuexContext, payload: string[]) {
    try {
      await axios.put(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${getters.sectionCells?.id}`, {
        autorefresh: getters.sectionCells?.autorefresh,
        showFilter: getters.sectionCells?.showFilter,
        columnStates: payload,
      });
      commit("setCells", payload);
    } catch (e) {
      console.log(e);
    }
  },
  async getCells({ rootGetters }: IVuexContext, payload: string) {
    try {
      const { data } = await getQuery<ISection>(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${rootGetters["auth/user"].name}/${payload}`);
      return data ?? null;
    } catch (e) {
      console.log(e);
    }
  },
  addInitMessagesLength({ commit }: IVuexContext, payload: number) {
    commit("addInitMessagesLength", payload);
  },
  setSelectedIds({ commit }: IVuexContext, payload: Record<string, boolean>) {
    commit("setSelectedIds", payload);
  },
  setIsSelectAll({ commit }: IVuexContext, payload: boolean | null) {
    commit("setIsSelectAll", payload);
  },
  refreshScroll({ commit, rootGetters }: IVuexContext) {
    commit("refreshData");
    setTimeout(() => {
      // TODO rewrite, error with infinite scroll this.$refs.infiniteLoader.getCurrentDistance() not refreshed to [] data
      commit("refreshInfiniteId");
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
    }, 100);
  },
  // eslint-disable-next-line no-empty-pattern
  async getOikAutocompleteItems({}: IVuexContext, value: string) {
    const { data } = await getQuery<IAutocompleteElement[]>(
      QUERY_PATH.GET_OIKS_AUTOCOMPLETE_ITEMS,
      {
        keyword: value,
      },
      false
    );
    if (data !== null) {
      return data.map((item: IAutocompleteElement) => ({
        ...item,
        titleValue: item.isSearchCode ? item.code : item.name,
      }));
    }
    return [];
  },
  async getAsyncExportData({ dispatch }: IVuexContext, uuid: string) {
    const queryParams = `?uuid=${uuid}`;
    await dispatch("app/openNewWindow", getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE, queryParams), {
      root: true,
    });
  },
  async getScreenshot({ rootGetters }: IVuexContext, payload: string) {
    const token = rootGetters["auth/user"]?.details?.token;

    let screenUrl;
    await fetch(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${payload}&origin=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        const blob = await response.blob();
        screenUrl = URL.createObjectURL(blob);
      })
      .catch((err) => {
        console.log(err);
      });

    return screenUrl;
  },
  async getTxtFile({ rootGetters }: IVuexContext, { uuid, offset, limit }: { uuid: string; offset: number; limit: number }) {
    const token = rootGetters["auth/user"]?.details?.token;
    return fetch(`${getFullPath(QUERY_PATH.GET_TXT_FILE_FROM_FILE_STORAGE)}?uuid=${uuid}&offset=${offset}&limit=${limit}&origin=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.text());
  },
};
